/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/
@import "variables";
@import "variables-custom";
@import "variables-dark";

//datatables
.kbunWL,
.ddeqMG {
  .cFDtZf,
  .efrTRK {
    color: $table-color;
  }

  :is(.kdYplh, .dUdLgu) {
    background-color: var(--#{$prefix}secondary-bg);
    border-bottom-color: var(--#{$prefix}border-color);
  }

  .dUdLgu {
    color: $table-color;
    &:not(:last-of-type) {
      border-bottom-color: var(--#{$prefix}border-color);
    }
  }
}

.card-body {
  .fjmwMg {
    color: $table-color;
    background-color: var(--#{$prefix}secondary-bg);
    border-top-color: var(--#{$prefix}border-color);

    .flbsZw {
      color: $table-color;
      fill: $table-color;
      &:disabled {
        color: var(--#{$prefix}tertiary-color);
        fill: var(--#{$prefix}tertiary-color);
      }
    }
  }
}

//react select
.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);
    &:hover,
    &:focus {
      border-color: var(--#{$prefix}primary);
    }

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);
      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  :is(.css-qbdosj-Input, .css-1dimb5e-singleValue) {
    color: var(--#{$prefix}body-color);
  }

  .css-t3ipsp-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);
      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);
    .css-d7l1ni-option {
      color: $white;
      background-color: var(--#{$prefix}primary);
      &:hover,
      &:active,
      &:focus {
        background-color: var(--#{$prefix}primary);
      }
    }
  }
}

.form-select-invalid {
  width: 100%;
  height: 40px;
  padding: 0px;
  //margin-top: -18px;

  @media (max-width: 270px) {
    width: 100%;
  }
}
.custom-select{
  &.filter-input {
    height: 38px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
.common-css {
  &.min-height-200 {
    min-height: 200px;
  }
  &.cursor-auto {
    cursor: "auto";
  }
  &.cursor-text {
    cursor: text;
  }
  &.top-6 {
    position: relative;
    top: 6px;
  }
  &.top-1 {
    position: relative;
    top: 1px;
  }
  &.top--2 {
    position: relative;
    top: -2px;
  }
  &.top--3 {
    position: relative;
    top: -3px;
  }
  &.top--4 {
    position: relative;
    top: -4px;
  }
  &.top--5 {
    position: relative;
    top: -5px;
  }
  &.top--6 {
    position: relative;
    top: -6px;
  }
  &.top--7 {
    position: relative;
    top: -7px;
  }
  &.top--8 {
    position: relative;
    top: -8px;
  }
  &.top--9 {
    position: relative;
    top: -9px;
  }
  &.top--10 {
    position: relative;
    top: -10px;
  }
  &.top--11 {
    position: relative;
    top: -11px;
  }
  &.top--12 {
    position: relative;
    top: -12px;
  }
  &.top--13 {
    position: relative;
    top: -13px;
  }
  &.top--14 {
    position: relative;
    top: -14px;
  }
  &.top--15 {
    position: relative;
    top: -15px;
  }
  &.margin-bottom--7 {
    position: relative;
    margin-bottom: -7px;
  }
  &.margin-bottom--8 {
    position: relative;
    margin-bottom: -8px;
  }
  &.margin-bottom--9 {
    position: relative;
    margin-bottom: -9px;
  }
  &.margin-bottom--10 {
    position: relative;
    margin-bottom: -10px;
  }
  &.padding-bottom-0 {
    padding-bottom: 0px;
  }
  &.float-right {
    float: right;
  }
  &.filter-flatpickr {
    font-size: 12px;
    background-image: url("../../../../assets/images/calendar-icon.png");
    background-position: 96% 50%;
    background-size: 20px;
    background-repeat: no-repeat;
  }
  &.filter-input {
    height: 38px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.common-pagination {
  background-color: var(--#{$prefix}secondary-bg);
  &.z-index-0 {
    z-index: 0;
  }
  &.item-min-width {
    min-width: 34.2px;
  }
  &.pagination-height {
    height: 49.5px;
  }
  &.pagination-result-limit {
    min-width: 110px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
  &.pagination-result-limit-child {
    width: 110px;

    @media (max-width: 768px) {
      width: auto;
      white-space: nowrap;

    }
  }
  &.pagination-alignment {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      margin: 20px 0 20px 0;
    }

    @media (min-width: 512px) {
      min-width: 522.13px;
    }

    @media (max-width: 511px) {
      width: 100%;
    }
  }
  &.pagination-buttons-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    width: 100%;

    @media (max-width: 600px) {
      display: inline;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  &.pagination-go {
    min-width: 100px;
    max-width: 100px;

    @media (max-width: 600px) {
      min-width: 355.19px;
      max-width: 355.19px;
    }
  }
  &.pagination-input {
    min-width: 35px;
    max-width: 35px;
  }
  &.pagination-excel-button {
    min-width: 100px;

    @media (max-width: 511px) {
      width: 100%;
    }
  }
  &.pagination-excel-button-child {
    width: 118px;
  }
  &.pagination-result-count {
    width: 249.06px;
  }
}

.scan-and-save {
  &.validation-width {
    width: 200px;
    display: block;
  }
  &.filter-input {
    width: 200px;
    height: 38px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.filters-col {
    width: 273.06px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.new-search {
  &.validation-width {
    width: 200px;
    display: block;
  }
  &.user-input {
    width: 200px;
    height: 40px;

    @media (max-width: 270px) {
      width: 100%;
    }
  }
  &.input-col {
    width: 250px;
  }
  &.check-col-first {
    min-width: 120px;
    max-width: 120px;
  }
  &.check-col-others {
    min-width: 155px;
    max-width: 155px;
  }
  &.upload-height {
    min-height: 213px !important;
    max-height: 213px !important;
  }
}

.shipments {
  &.validation-width {
    width: 200px;
    display: block;
  }
  &.filter-input {
    width: 200px;
    height: 38px;

    @media (max-width: 270px) {
      width: 100%;
    }
  }
  &.filters-col {
    width: 330.06px;
  }
}

.new-shipment {
  &.validation-width {
    width: 200px;
    display: block;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.user-input {
    width: 200px;
    height: 38px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.input-col {
    width: 250px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.shipment-details {
  &.input-group-size {
    width: 110px;
    float: right;
  }
  &.input-size {
    width: 60px;
    float: right;
  }
  &.validation {
    min-width: 250px;
    display: inline-block;
  }
  &.user-input {
    width: 200px;
    height: 38px;

    @media (max-width: 270px) {
      width: 100%;
    }
  }
  &.input-col {
    width: 250px;
  }
  &.margin-left {
    margin-left: -25px;

    @media (max-width: 992px) {
      margin-left: 0px;
    }
  }
  &.input-checkbox {
    margin-top: 20px;

    @media (max-width: 992px) {
      margin-top: -20px;
    }
  }
}
.shipment-items {
  &.trBody {
    height: 70px;
  }
  &.validation {
    margin-bottom: -21px;
  }
}

.shipment-finance-items {
  .input-group-text {
    background-color: #fff;
    font-size: 11px !important;
    border-bottom-right-radius: 3.2px !important;
    border-top-right-radius: 3.2px !important;

    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
  }
  .border-input-group {
    border-right: 0;
  }
  .input-group-text {
    border-left: 0;
  }
  .input-group:focus-within .form-control {
    border: 1px solid #a9afb5;
    border-right: 0;
  }

  .input-group:focus-within .input-group-text {
    border: 1px solid #a9afb5;
    border-left: 0;
  }
}

//#region react-multi-select-component
.ellipsis-for-option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  display: block;
  width: 100%;
}

.item-renderer {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 8px; 
}

.option-checkbox-style {
  flex-shrink: 0; 
  width: 15px;  
  height: 15px; 
  margin: 0;    
  padding: 0;   
}

.option-label-style {
  flex-grow: 1; 
  overflow: hidden;
}
//#endregion

.ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}


.ellipsis-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.ellipsis-single-line-category {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70%;
  text-align: right;
  white-space: nowrap;
  display: inline-block;
}

.nowrap, time {
  white-space: nowrap !important;
}

.card-item-checked {
  border-color: $secondary !important;
}
.card-item-checked::before {
  color: $secondary;
  content: "";
  font-family: remixicon;
  font-size: 16px;
  position: absolute;
  right: 6px;
  top: 2px;
}

.card-item-checked-lg {
  border: 1px solid #b0caff !important;
}
.card-item-checked-lg::before {
  color: $secondary;
  content: "";
  font-family: remixicon;
  font-size: 24px;
  position: absolute;
  right: 6px;
  top: 2px;
}

.product-img-container {
  max-height: 150px;
  overflow: hidden;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  .my-masonry-grid_column {
    padding-right: 25px; /* gutter size */
    background-clip: padding-box;
  }
  .my-masonry-grid_column:last-child {
    padding-right: 0;
  }
}

.search-results-filters {
  .form-select-sm {
    padding-left: 0px;
  }
}

.search-results-other-fees {
  min-width: 350px;
  background-color: white;
}

hr {
  color: #9fa3a4;
}

.country-list {
  .country-name,
  .dial-code {
    font-size: 14px !important;
  }
}
.width-50 {
  width: 50px !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.StackGrid-component > div {
  opacity: 1 !important;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.same-height {
  display: flex;
  flex-direction: column;
}
.same-height .card {
  flex: 1;
}

.custom-width {
  width: -moz-min-content; /* Firefox */
  width: -webkit-min-content; /* Safari/Chrome */
  width: min-content;
}

.z-index-0{
  z-index: 0 !important;
}

.limits-badge{
  line-height: 0.95;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: var(--vz-badge-font-size);
  font-weight: var(--vz-badge-font-weight);
}
.pagination{
  padding: 0!important;
  margin: 0!important;
}

.bg-warning-limits-badge{
  background-color: #ffe8c4;
}

.text-sellthis{
  color: #4b11ff;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  border-color: transparent;
  opacity: 0.4;
}

.tooltip-safe-distance{
  bottom: -3px;
}

.placeholder{
  background-color: transparent !important;
  user-select: none !important;
  pointer-events: none !important;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: var(--vz-border-color) !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: var(--vz-input-bg-custom);
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
  --rmsc-gray: #807f7f;
}

.rmsc .dropdown-content{
  z-index: 3 !important;
}
.rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: #1d1915;
  --rmsc-border: var(--vz-border-color) !important;
  --rmsc-gray: #807f7f;
  --rmsc-bg: var(--vz-input-bg-custom);
  color: #fff;
}

.search-result-item-table {
  .search-result-item {
    background-color: var(--vz-search-result-item-bg-color) !important;
    td {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .search-result-item:first-of-type {
    > td > div > .card:first-of-type {
      padding-top: 10px;
      border-image: 
          linear-gradient(
            to bottom, 
            transparent 0%, 
            var(--vz-search-result-item-border-color)
          ) 1 100% !important;
    }
    > td > div > .card:first-of-type > .card-header:first-of-type {
      border-radius: 0%;
      padding-top: 30px;
      border-top: 1px solid var(--vz-search-result-item-border-top);
    }
  }
  .search-result-item:last-of-type {
    background-color: white !important;
    > td > div > .card:first-of-type {
      box-shadow: none;
      border-bottom-left-radius: 0%;
      border-bottom-right-radius: 0%;
      margin-bottom: 10px;
      border-image: 
          linear-gradient(
            to bottom, 
            var(--vz-search-result-item-border-color), 
            white
          ) 1 100% !important;
    }
    > td > div > .card:first-of-type > .collapse > .card-body:last-child {
      border-bottom: 1px solid #f3f3f8;
      padding-bottom: 10px !important;
    }
  }
}

.text-end::placeholder {
  text-align: right;
}

.bg-filter-chips{
  background-color: var(--vz-success-bg-subtle) !important;
  font-weight: normal!important;
  &:hover,
  &:focus {
    background-color: var(--vz-danger-bg-subtle) !important;
  }
}

.pimage-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.cursor-01{
  cursor: url(data:image/webp;base64,UklGRrYCAABXRUJQVlA4WAoAAAAYAAAAHwAAEQAAQUxQSEAAAAABDzD/ERGCaWTbTg4ZCT618R2d/U9plITMHEcNEf0P1gK2GfYFDuEw4zNDs1b7QUPU6KfxS3F4UmiP1HEssP8BVlA4II4BAABQCgCdASogABIAPlEejEQjoaEYBAA4BQS0gAiIn4jZMX7TNAKaj/dvtA9oDya/Ln/C9wL+Efy//C/mt3APQA/SUdHM2XhUzJxKsEeXtw/owV++KhSphd/lnOLgAP7+7L3bCmcYT0655SSFyPoO6HQTd9Ul9i41vCCstQN7wph/7B8WriPLeVF867P/mb/Fd0a5oPMcBSg8FMbWmHwdP7//Zi3+Ck8T2UvOzZT2UaTFD/0nMDLnWxP2g/pFlup/qVP0ipXW0OznErqoYdMgDKMgrQu5l69X18pPR67NMpGl99I897RbisDc4tVd/MDMhsia1kdRtW9ztY4qQ4PBlXwdFibvgOwHVpz/GVd2siIsiaEFMPo0sgccs4pYUw4x6a7yXILSmoV/pyJe3+LK3Lw5Q+sn37q/xfwTyP85wPM5vePOH2IxZhdLseXsBQkirkf/kol//f1j9j3X/fV//1x0VLU3gIw7CbPR0pRt0FX6P/hCFu+OlEYf9wHNxP6hrv/h++lij0HPmQ69n6AAAEVYSUa6AAAARXhpZgAASUkqAAgAAAAGABIBAwABAAAAAQAAABoBBQABAAAAVgAAABsBBQABAAAAXgAAACgBAwABAAAAAgAAABMCAwABAAAAAQAAAGmHBAABAAAAZgAAAAAAAABIAAAAAQAAAEgAAAABAAAABgAAkAcABAAAADAyMTABkQcABAAAAAECAwAAoAcABAAAADAxMDABoAMAAQAAAP//AAACoAQAAQAAACAAAAADoAQAAQAAABIAAAAAAAAA) 16 9,ew-resize
}

.sticky-column-left {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}

tfoot td.sticky-column-left, th.sticky-column-left {
  background-color: var(--vz-table-bg) !important;
}

td.sticky-column-left {
  background-color: var(--#{$prefix}footer-bg) !important;
}

.custom-no-border {
  border: 0px solid var(--#{$prefix}dark-bg-subtle);
}
